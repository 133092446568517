import React from "react"
import logo from "../../images/logo.svg";

const Header = React.forwardRef((props, ref) => {
	return (
        <header id="nav-header" className={"header"} ref={ref}>
            <div className="padding"></div>
            <div className={"header__logo"}>
                <img src={logo}
                    alt="DefenseArk formerly OpenAVN"
                />
            </div>
        </header>
	)
})

export default Header
