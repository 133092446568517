import React from "react";
import Mailchimp from "./Mailchimp"

const Content = () => {
    return (
        <main>
            <div className={"content"}>
                <div className="content__container">
                    <div className="content__header">
                        <h2 className="content__header--h2">Ready to take control of your cyber posture?</h2>
                    </div>
                    <div className="content__info">
                        <p>DefenseArk delivers cutting edge cyber threat intelligence in realtime. BrightScan XDR is our flagship endpoint defense software that provides decentralized realtime threat intel to strengthen business continuity & system visibility. Sign up to create a new account.</p>
                    </div>
                    <div className="content__subscribe">
                        <Mailchimp />
                    </div>
                </div>
            </div>
        </main>
    )
};

export default Content
