import React from 'react';
import Layout from './components/Layout';
import './styles/index.scss';

function App() {
	return (
		<Layout />
	);
}

export default App;
