import React, {useRef, useEffect, useState} from "react";

const Content = () => {

    const arr = new Array(19).fill({offset:0, dashSize:0})
    const pathRef = useRef()
    const [ offsets, setOffsets ] = useState(arr)

    useEffect( () =>{

        const arr = []
        let { children } = pathRef.current
        children = Array.from(children)
        children.forEach(child => {
            const length = child.getTotalLength();
            const obj = {}
            const randSize = Math.random()*100 + 20

            obj.offsetEnd = length*2+ randSize*2;
            obj.offsetStart = length + randSize;
            obj.dash = `${randSize} ${length*2}`
            obj.dashSize = randSize
            obj.speed = (length+randSize) * 1.5 + Math.random()
            obj.transition = '';


            setTimeout(()=>{

                const fn = () =>{
                    const {offsetEnd, offsetStart} = obj
                    obj.offsetStart = offsetEnd
                    obj.offsetEnd = offsetStart
                    obj.transition = `stroke-dashoffset ${obj.speed}ms linear`

                    setOffsets(Array.from(arr))
                }

                fn()
                obj.interval = setInterval( fn, Math.random()*7000 + 2000)
            }, Math.random()*3000)


            arr.push(obj)
        })
        setOffsets(arr)

    }, [])

    return (
        <div className="background">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 1600 800"  xmlSpace="preserve" fill="none" strokeWidth="1px" stroke="white" preserveAspectRatio="xMidYMid slice" >
                <filter id="blurMe">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="2" />
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                <g id="Layer_1_copy_2">
                    <g transform="translate(-100 -50)" stroke="#292269">

                        <path  d="M403.6,133.3L293.3,69.5l-76.9,44.4L100,181.2l-49.1,28.3l-57.5-33.2V241l57.4,33.2l76.9-44.4l116.5-67.2
                            l49.3-28.5l110.2,63.7l170.1-98.2l23.2-13.4l32.1-18.5l54,31.2l-65.4,37.8l-7.5,4.3L402.9,260.6l-109.5-63l-49.3,28.5L183,261.4
                            L51,337.5l-57.6-33.2v64l57.4,33.2l187.3-108.2l6-3.5l49.3-28.5l54,31.2l-82.6,47.7l-21.8,12.6L50.1,464.4l-56.6-32.6v63.9
                            l1.2,0.7l-1.2,0.7v64.3l216.4-124.9l110.2,63.7l49.6-28.7l6-3.5l55.1-31.8l132-76.2L673,423.7l59.7-34.5l133.7-77.2l32.1-18.6
                            l110.2,63.7l179-103.4l14.1-8.1l41-23.7l55.2,32l-96.4,55.7l-85.2,49.2l-108.2,62.4L898,357.6l-32.1,18.5l-78.3,45.2l-114.8,66.3
                            l-109.9-63.9L486,468.2l-116.5,67.2l-49.1,28.3L210.2,500L40.1,598.2l-23.2,13.4l-23.4,13.5v63.5l23.4-13.5l23.2-13.4l55.1-31.8
                            l114.8-66.3l110.2,63.7L397,583l116.5-67.2l49.3-28.5L673.1,551l170.1-98.2l23.2-13.4l32.1-18.5l55.2,32l-55.4,32l-225.2,130
                            L563,551.1l-49.3,28.5l-61.1,35.3l-132,76.2L210,627.5l-59.7,34.5L16.7,739.1l-23.2,13.4V816l50.4-29.1l133.7-77.2l32.1-18.5
                            l110.2,63.7l187.3-108.2l6-3.5l49.3-28.5l55.2,32l-104.4,60.3l-76.9,44.4l-116.2,67.1l-110.2-63.7L178,773.4l-78.3,45.2
                            L-6.5,879.9v63.5l161-92.9l23.2-13.4l32.1-18.5l55.2,32l-55.1,31.8l-155.8,90h115.3L383,848.9l110.2,63.7l49.3-28.5l6-3.5
                            l55.1-31.8l132-76.2l110.2,63.7l59.7-34.5l133.7-77.2l32.1-18.6l110.2,63.7l179-103.4l14.1-8.1l41-23.7l55.2,32l-96.4,55.7
                            l-85.2,49.2l-108.2,62.4l-110.2-63.7l-31.3,18.1l-78.3,45.2l-114.8,66.2l-110.2-63.7l-76.9,44.4L543,947.8l-42.4,24.5h112
                            l74.3-42.9l49.3-28.5l110.2,63.7l170.1-98.2l23.2-13.4l32.1-18.5l54,31.2l-65.4,37.8l-7.5,4.3l-111.9,64.6h127.9l-8.3-4.8
                            l55.1-31.8l225.2-130l110.2,63.7l41-23.7l14.1-8.1l55.1-31.8l123.9-71.6l110.2,63.7l59.7-34.5l50.7-29.3v-63.5l-77.3,44.7
                            l-32.1,18.6l-110.2-63.7l-179,103.4l-14.1,8.1l-41,23.7l-55.2-32l55.1-31.8l234.4-135.3l110.2,63.7l32.1-18.6l23.2-13.4l53.9-31.1
                            v-63.5l-54.2,31.3l-23.2,13.4l-32.1,18.5l-55.2-32l55.1-31.8l109.7-63.3v-63.9l-43.5,25.1L1752.3,377l-32.4,18.7l-78.3,45.2
                            l-114.8,66.3l-110.2-63.7l-68.6,39.6l-124.5,71.9l-41.3,23.9l-110.2-63.7l-170.1,98.2l-23.2,13.4l-32.1,18.6l-55.2-32l55.1-31.8
                            l225.2-130l110.2,63.7l41-23.7l14.1-8.1l55.1-31.8l123.9-71.6l110.2,63.7l59.7-34.5l133.7-77.2l32.1-18.5l110.2,63.7l44.2-25.5
                            v-63.5l-43.6,25.2l-110.2-63.7l-59.7,34.5l-133.7,77.2l-32.1,18.6l-110.2-63.7l-179,103.4l-14.1,8.1l-41,23.7l-55.2-32l55.1-31.8
                            l234.4-135.3l110.2,63.7l32.1-18.5l23.2-13.4l55.1-31.8l114.8-66.3l110.2,63.7l43.7-25.2V162l-43.2,24.9l-110.2-63.7l-170.1,98.2
                            l-23.2,13.4l-32.1,18.5l-55.2-32l55.1-31.8l225.2-130l110.2,63.7l43.6-25.2V34.6l-43.2,25l-55.2-32l52.8-30.5h-117.3l-53.7,31
                            l-53.7-31h-113.2l-53.8,31.1l-114.7,66.3l-110.2-63.7l-68.6,39.6l-124.5,71.9l-41.6,24l-110.2-63.7l-170.1,98.2l-23.2,13.4
                            l-32.1,18.6l-55.2-32l55.1-31.8l225.2-130l110.2,63.7l41.3-23.9l14.1-8.1l55.1-31.8l72.2-41.7h-110l-16.6,9.6l-14.1,8.1l-41,23.7
                            l-55.2-32l16.3-9.4H859.9l-13.1,7.5L738.7,67.1L628.8,3.2l-32.1,18.5L518.3,67L403.6,133.3z M846.1,708.9l32.1-18.6l23.2-13.4
                            l55.1-31.8l114.8-66.3l110.2,63.7l68.6-39.6l124.5-71.9l41-23.7l110.2,63.7L1696,473l23.2-13.4l32.1-18.5l55.2,32l-55.1,31.8
                            l-225.2,130L1416,571.1l-40.7,23.5l-69.4,40.1L1182,706.3l-110.2-63.7L1012,677l-133.7,77.2l-31.8,18.4l-110.2-63.7L549,817
                            l-6,3.5L493.6,849l-55.2-32l55.1-31.8l242.4-140L846.1,708.9z M672.9,296.4l32.4-18.7l23.2-13.4l55.1-31.8l114.8-66.3l110.2,63.7
                            l68.6-39.6l124.5-71.9l41-23.7l110.2,63.7l170.1-98.2l23.2-13.4l32.1-18.5l55.2,32l-55.1,31.8l-225.2,130l-110.2-63.7l-41,23.7
                            l-69.4,40.1l-123.9,71.6L898.5,230l-59.7,34.5l-133.7,77.2L673.3,360l-110.2-63.7L375.8,404.4l-6,3.5l-49.3,28.5l-55.2-32
                            l55.1-31.8l242.4-140L672.9,296.4z"/>
                    </g>
                </g>
                <g ref={pathRef} id="Layer_1_copy"   transform="translate(-100 -50)" strokeWidth="1px" stroke="#443B95"  >
                    <path  style={{ transition: offsets[1].transition}} strokeDasharray={offsets[0].dash} strokeDashoffset={ offsets[0].offsetEnd} d="M108.7,622.1l101.2-58.4l110.2,63.7L397,583l116.5-67.2l49.3-28.5"/>
                    <path style={{ transition: offsets[1].transition}} strokeDasharray={offsets[1].dash} strokeDashoffset={ offsets[1].offsetEnd} d="M1889.7,425l-27.2,15.7"/>
                    <path style={{ transition: offsets[2].transition}} strokeDasharray={offsets[2].dash} strokeDashoffset={ offsets[2].offsetEnd} d="M1696,473l23.2-13.4"/>
                    <path style={{ transition: offsets[3].transition}} strokeDasharray={offsets[3].dash} strokeDashoffset={ offsets[3].offsetEnd} d="M1686.3,670.2l-179,103.4l-14.1,8.1l-41,23.7"/>
                    <path style={{ transition: offsets[4].transition}} strokeDasharray={offsets[4].dash} strokeDashoffset={ offsets[4].offsetEnd} d="M878.4,754.2l-31.8,18.4"/>
                    <path style={{ transition: offsets[5].transition}} strokeDasharray={offsets[5].dash} strokeDashoffset={ offsets[5].offsetEnd} d="M791.2,613.2l55.1-31.8"/>
                    <path style={{ transition: offsets[6].transition}} strokeDasharray={offsets[6].dash} strokeDashoffset={ offsets[6].offsetEnd} d="M1077.2,190.3l124.5-71.9l41-23.7l110.2,63.7"/>
                    <path style={{ transition: offsets[7].transition}} strokeDasharray={offsets[7].dash} strokeDashoffset={ offsets[7].offsetEnd} d="M1752.3,377l-225.5,130.2l-110.2-63.7l-68.6,39.6"/>
                    <path style={{ transition: offsets[8].transition}} strokeDasharray={offsets[8].dash} strokeDashoffset={ offsets[8].offsetEnd} d="M1250.1,603.1l165.5-95.6l110.2,63.7"/>
                    <path style={{ transition: offsets[9].transition}} strokeDasharray={offsets[9].dash} strokeDashoffset={ offsets[9].offsetEnd} d="M1526.3,634.9L1416,571.1l-40.7,23.5"/>
                    <path style={{ transition: offsets[10].transition}} strokeDasharray={offsets[10].dash} strokeDashoffset={ offsets[10].offsetEnd} d="M1305.9,634.7L1182,706.3l-110.2-63.7L1012,677"/>
                    <path style={{ transition: offsets[11].transition}} strokeDasharray={offsets[11].dash} strokeDashoffset={ offsets[11].offsetEnd} d="M736.3,708.9L549,817l-6,3.5"/>
                    <path style={{ transition: offsets[12].transition}} strokeDasharray={offsets[12].dash} strokeDashoffset={ offsets[12].offsetEnd} d="M438.4,817l55.1-31.8l242.4-140l110.2,63.7l32.1-18.6l23.2-13.4l55.1-31.8l114.8-66.3l110.2,63.7"/>
                    <path style={{ transition: offsets[13].transition}} strokeDasharray={offsets[13].dash} strokeDashoffset={ offsets[13].offsetEnd} d="M154.4,850.5l23.2-13.4l32.1-18.5l55.2,32l-55.1,31.8l-101.2,58.4"/>
                    <path style={{ transition: offsets[14].transition}} strokeDasharray={offsets[14].dash} strokeDashoffset={ offsets[14].offsetEnd} d="M507.3,646.8l6-3.5l49.3-28.5l55.2,32l-104.4,60.3l-76.9,44.4l-116.2,67.1l-110.2-63.7L178,773.4"/>
                    <path style={{ transition: offsets[15].transition}} strokeDasharray={offsets[15].dash} strokeDashoffset={ offsets[15].offsetEnd} d="M1008.8,293.7L898.5,230l-59.7,34.5l-133.7,77.2L673.3,360l-110.2-63.7L375.8,404.4l-6,3.5"/>
                    <path style={{ transition: offsets[16].transition}} strokeDasharray={offsets[16].dash} strokeDashoffset={ offsets[16].offsetEnd} d="M1353.4,222.1l-110.2-63.7l-41,23.7l-69.4,40.1"/>
                    <path style={{ transition: offsets[17].transition}} strokeDasharray={offsets[17].dash} strokeDashoffset={ offsets[17].offsetEnd} d="M783.7,232.4l114.8-66.3l110.2,63.7"/>
                    <path style={{ transition: offsets[18].transition}} strokeDasharray={offsets[18].dash} strokeDashoffset={ offsets[18].offsetEnd} d="M562.7,232.6l110.2,63.7l32.4-18.7l23.2-13.4"/>
                </g>
            </svg>


        </div>
    )
};

export default Content

