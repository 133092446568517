import React from "react"

const Footer = () => {
    return (
        <div className={"footer"}>
            <p><span className="footer__copyright">Copyright © 2019-2023</span> | All Rights Reserved - DefenseArk Security.</p>
        </div>
    )
};

export default Footer
