import { css } from 'emotion'
/*
Circular Std
*/
import NotoSansMediumTtf from "./Noto_Sans/NotoSans-Bold.ttf";
import NotoSansMediumWoff from "./Noto_Sans/notosans-bold.woff";
import NotoSansMediumWoff2 from "./Noto_Sans/notosans-bold.woff2";
import NotoSansLightTtf from "./Noto_Sans/notosans-light.ttf";
import NotoSansLightWoff from "./Noto_Sans/notosans-light.woff";
import NotoSansLightWoff2 from "./Noto_Sans/notosans-light.woff2";

export default css`
    @font-face {
		font-family: "Noto Sans";
		src: url("${NotoSansLightWoff2}") format("woff2"),
			url("${NotoSansLightWoff}") format("woff"),
			url("${NotoSansLightTtf}") format("truetype");
		font-weight: 400;
    }

    @font-face {
		font-family: "Noto Sans";
		src: url("${NotoSansMediumWoff2}") format("woff2"),
			url("${NotoSansMediumWoff}") format("woff"),
			url("${NotoSansMediumTtf}") format("truetype");
		font-weight: 700;
    }
`;
