import React from 'react';
import Background from './context/Background';
import Header from './context/Header';
import Footer from './context/Footer';
import Content from './context/Content';
import notoSans from "../typography/noto-sans";

function Layout() {
	return (
        <div style={{notoSans}}>
            <Background />
			<Header />
			<Content />
			<Footer />
		</div>
	);
}

export default Layout;
