import React from "react"
import MailchimpSubscribe from "react-mailchimp-subscribe"

function Form() {
    const url = "https://openavn.us4.list-manage.com/subscribe/post?u=c624b931e46a746faf44678a0&id=c62170c9ce";

    const MyForm = ({ status, message, onValidated }) => {
        let email;
        const submit = () => {
            if(email || email.value.indexOf("@") > -1 || !email.value) {
                onValidated({
                    EMAIL: email.value
                });
            }
        }

        const _handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                submit();
            }
        }

        return (
            <>
            <div className={"newsletter-signup-form-container"}>
                {status !== "success" && (
                    <div className={"newsletter-signup-form-container__newsletter-signup-form"}>
                        <div className={"input-wrapper"}>
                            <input type="email" name="Email" id="email" placeholder="Enter your email address" onFocus={(e) => e.target.placeholder = ""} onKeyDown={_handleKeyDown} onBlur={(e) => e.target.placeholder = "Enter your email address"}  required ref={node => (email = node)} />
                            <i></i>
                        </div>

                        <button type="submit" className={"button"} onClick={submit}>
                            Get early access
                        </button>
                    </div>
                )}

            </div>

            <div className="newsletter-signup-form-container__message-container">
                {status === "error" && status !== "sending" && (
                    <div className={`errors ${status === "error" ? "has-error" : ""}`} data-for="email">
                        <span className='newsletter-signup-form-container__error'>
                            {(message.indexOf("already") > -1) ? `Too many attempts for this email address.` : 'Please enter valid email address.' }
                        </span>
                    </div>
                )}
            </div>
            {status === "success" && (
                <div className={"newsletter-signup-form-container__newsletter-signup-form"}>
                    <div className={"newsletter-signup-form-container__thank-you"}>
                        <p>Thank you for your interest in OpenAVN and our upcoming products. You will be among the first to receive access, and we will keep you up to date with our latest news.</p>
                    </div>
                </div>
            )}
            {status !== "success" && (
                <div className={"newsletter-signup-form-container__newsletter-signup-form newsletter-signup-form-container__newsletter-signup-form--mobile"}>
                    <button type="submit" className={"button newsletter-signup-form-container__newsletter-signup-form--mobile-btn"} onClick={submit}>
                        Get early access
                    </button>
                </div>
            )}
            </>
        );
    };
    const form = (
        <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
                <MyForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )

    return (
        <>
            {form}
        </>
    )
}

export default Form
